<template>
	<PaymentSummaryCard :payment-lines="filteredPaymentLines" />
</template>

<script setup>
import { useStore } from "vuex";
import {
	productIsEligibleForDownPayment,
	productIsEligibleForSwap,
	getValidatedSubscriptionPrice,
} from "~/helpers/ecommerce/hardwareOrderHelper";
import { formatPrice } from "~/helpers/formatting/price";

const props = defineProps({
	showSubItems: {
		type: Boolean,
	},
	shoppingCart: {
		type: Boolean,
		default: false,
	},
});

const store = useStore();

const visibleCartItems = computed(() => store.getters["shoppingCart/visibleCartItems"]);
const visibleSubscriptionsInCart = computed(() => store.getters["shoppingCart/visibleSubscriptionsInCart"]);
const isVatExempt = computed(() => store.getters["shoppingCart/isVatExempt"]);
const subscriptions = computed(() => store.getters["shoppingCart/subscriptions"]);

const validatedSubscriptionPrices = computed(() =>
	getValidatedSubscriptionPrice(subscriptions.value, visibleSubscriptionsInCart.value),
);

const totalMonthly = computed(() => {
	const getCorrectMonthlyPrice = (item) => {
		const isExistingSubscription = (item) => item?.type === "subscription" && item?.metadata?.existingSubscription;
		if (item?.metadata?.category === "Smartklokker") {
			return item?.metadata?.downPayment && item.metadata?.installmentsInMonths
				? item?.metadata?.downPaymentPlan?.prices?.monthlyListPrice.value
				: 0;
		} else if (productIsEligibleForSwap(item) || productIsEligibleForDownPayment(item)) {
			return item?.metadata?.swap ? item?.metadata?.saleRecurringPrice || item?.metadata?.recurringPrice : 0;
		} else {
			return isExistingSubscription(item)
				? 0
				: item.type === "subscription"
					? validatedSubscriptionPrices.value?.originalPrice
						? validatedSubscriptionPrices.value?.discountedPrice || validatedSubscriptionPrices.value?.originalPrice
						: item.metadata?.recurringPrice || 0
					: item.metadata?.saleRecurringPrice || item.metadata?.recurringPrice || 0;
		}
	};
	return formatPrice.oneTime(
		visibleCartItems.value
			.filter((item) => item?.type !== "old-swap-device")
			.map((item) => (props.showSubItems ? [item, ...(item?.subItems ?? {})] : [item]))
			.flat()
			.map(getCorrectMonthlyPrice)
			.reduce((a, b) => a + b, 0),
	);
});

const totalOneTime = computed(() => {
	const getCorrectOneTimePrice = (item) => {
		if (productIsEligibleForSwap(item) || productIsEligibleForDownPayment(item)) {
			if (isVatExempt.value) {
				return item.metadata?.downPayment && item.metadata?.installmentsInMonths
					? 0
					: item?.metadata?.swap
						? item?.metadata?.memberPrice?.prices?.withoutVat?.memberPrice &&
							item?.subItems?.some((subItem) => subItem.type === "subscription")
							? item?.metadata?.memberPrice?.prices?.withoutVat?.memberPrice
							: item.metadata.salePriceWithoutVat || item.metadata.priceWithoutVat
						: 0;
			} else {
				return item.metadata?.downPayment && item.metadata?.installmentsInMonths
					? 0
					: !item?.metadata?.swap
						? item?.metadata?.memberPrice?.prices?.withVat?.memberPrice &&
							item?.subItems?.some((subItem) => subItem.type === "subscription")
							? item?.metadata?.memberPrice?.prices?.withVat?.memberPrice
							: item.metadata.salePrice || item.metadata.price
						: 0;
			}
		} else {
			if (isVatExempt.value) {
				return item?.metadata?.memberPrice?.prices?.withoutVat?.memberPrice &&
					item?.subItems?.some((subItem) => subItem.type === "subscription")
					? item?.metadata?.memberPrice?.prices?.withoutVat?.memberPrice
					: item.metadata.salePriceWithoutVat || item.metadata.priceWithoutVat || item.data.price || 0;
			} else {
				return item?.metadata?.memberPrice?.prices?.withVat?.memberPrice &&
					item?.subItems?.some((subItem) => subItem.type === "subscription")
					? item?.metadata?.memberPrice?.prices?.withVat?.memberPrice
					: item.metadata.salePrice || item.metadata.price || item.data.price || 0;
			}
		}
	};
	return formatPrice.oneTime(
		visibleCartItems.value
			.filter((item) => item?.type !== "old-swap-device")
			.map((item) => (props.showSubItems ? [item, ...(item?.subItems ?? {})] : [item]))
			.flat()
			.map(getCorrectOneTimePrice)
			.reduce((a, b) => a + b, 0),
		{ round: true },
	);
});

const paymentLines = computed(() => [
	{
		id: "SUM",
		name: "Sum",
		monthlyPaymentPrice: totalMonthly.value,
		payNowPrice: totalOneTime.value,
		hasDiscount: false,
		show: false,
	},
	{
		id: "DISCOUNT",
		name: "Rabatter",
		monthlyPaymentPrice: "--",
		payNowPrice: "--",
		hasDiscount: false,
		show: false,
	},
	{
		id: "TOTAL_TO_PAY",
		name: "Å Betale",
		monthlyPaymentPrice: totalMonthly.value,
		payNowPrice: totalOneTime.value,
		hasDiscount: false,
		show: true,
	},
]);

const filteredPaymentLines = computed(() => paymentLines.value.filter((line) => line.show));
</script>
